  import React from 'react';
  import './styles/Home.css'; // Import the CSS file
  import logo from './assets/Logo.png'; // Import the logo

  const Home = () => {
    return (
      <div className="App">
        <br></br><br></br><br></br><br></br>
        <header className="header">
          <div className="header-content">
            <h1 className="company-title">ADAMAS INNOVATION DIGITAL SOLUTIONS</h1>
            <img src={logo} alt="Adamas Logo" className="logo" />
          </div>
          <hr className="horizontal-line" /> {/* Add a horizontal line */}
          <div className="content-area">
          Adamas Innovation Digital Solutions, established in 2023, is dedicated to delivering a diverse range of digital services tailored to meet the needs of modern businesses and organizations. The company excels in Game Development and Software Development, creating engaging experiences and efficient software solutions. It also offers Hosting Professional Services, ensuring secure, high-performance environments for all types of applications. With a strong focus on business continuity, Adamas provides Disaster Recovery services that safeguard critical data and minimize operational disruptions.

  Further solidifying its role as a leader in digital transformation, Adamas Innovation Digital Solutions offers specialized products such as the Integrated Local Government Unit System (ILGUS), designed to enhance local governance through streamlined, integrated processes. The Adamas Enterprise Resource Planning (AERP) system provides comprehensive tools for managing and optimizing business operations, driving efficiency, and promoting growth. With a commitment to innovation and quality, Adamas is positioned to empower businesses and government units with the technology and solutions they need to thrive in an ever-evolving digital landscape.
          </div>
        </header>
      </div>
    );
  };

  export default Home;
