import { Link } from 'react-router-dom';
import React from 'react';
import './styles/Home.css'; // Import the CSS file
export default function NavigationBar(){

return(
<nav className="navbar">
<ul className="nav-links">
<li><Link to="/">Home</Link></li>
  <li><Link to="/about-us">About Us</Link></li>
  <li><Link to="/product">Product</Link></li>
  <li><Link to="/ContactUs">Contact</Link></li>
</ul>
</nav>
)
}
