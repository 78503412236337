// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/Home.css'; // Import the CSS file
import Home from './Home'; // Import the main App component
import AboutUs from './AboutUs'; // Import the AboutUs component
import Product from './Product';
import ContactUs from './ContactUs';
import NavigationBar from './NavigationBar';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <NavigationBar/>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/Product" element={<Product />} />
      <Route path="/ContactUs" element={<ContactUs />} />
    </Routes>
  </Router>
);
