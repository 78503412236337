import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles
import './styles/Product.css'; // Import your custom CSS
import Lenovo from '../src/assets/Lenovo.png';
import Acer from '../src/assets/Acer.png';
import Altos from '../src/assets/Altos.jpg';
import Asus from '../src/assets/Asus.png';
import Cisco from '../src/assets/Cisco.png';
import Dell from '../src/assets/Dell.png';
import Fortinet from '../src/assets/Fortinet.png';
import HPE from '../src/assets/HPE.png';
import Huawei from '../src/assets/Huawei.png';
import Microsoft from '../src/assets/Microsoft.jpg';
import Samsung from '../src/assets/Samsung.png';
import Sangfor from '../src/assets/Sangfor.png';
import Sophos from '../src/assets/Sophos.png';
import Supermicro from '../src/assets/Supermicro.png';
import vmware from '../src/assets/vmware.png';
import GameDev from '../src/assets/GameDev.png';
import SoftDev from '../src/assets/SoftDev.png';
import Hosting from '../src/assets/Hosting.png';
import DR from '../src/assets/DR.png';
import ILGUS from '../src/assets/ILGUS.png';
import AERP from '../src/assets/AERP.png';
import Textblast from '../src/assets/GameDev.png';
import ProfServices from '../src/assets/ProfServices.png';

// Example product data
const products = [
  { id: 1, name: 'Lenovo', image: Lenovo },
  { id: 2, name: 'Acer', image: Acer },
  { id: 3, name: 'Altos', image: Altos },
  { id: 4, name: 'Asus', image: Asus },
  { id: 5, name: 'Cisco', image: Cisco },
  { id: 6, name: 'Dell', image: Dell },
  { id: 7, name: 'Fortinet', image: Fortinet },
  { id: 8, name: 'HPE', image: HPE },
  { id: 9, name: 'Huawei', image: Huawei },
  { id: 10, name: 'Microsoft', image: Microsoft },
  { id: 11, name: 'Samsung', image: Samsung },
  { id: 12, name: 'Sangfor', image: Sangfor },
  { id: 13, name: 'Sophos', image: Sophos },
  { id: 14, name: 'Supermicro', image: Supermicro },
  { id: 15, name: 'VMware', image: vmware },
];

const offerings = [
  { id: 1, logo: GameDev, title: 'Game Development', description: 'High-performance hardware solutions for your needs.' },
  { id: 2, logo: SoftDev, title: 'Software Development', description: 'Custom software solutions tailored to your business.' },
  { id: 3, logo: Hosting, title: 'Hosting', description: 'Reliable hosting services with top-notch support.' },
  { id: 4, logo: ProfServices, title: 'Professional Services', description: 'Expert consultation and services for your business.' },
  { id: 5, logo: DR, title: 'Disaster Recovery', description: 'Comprehensive disaster recovery solutions to protect your data.' },
  { id: 6, logo: ILGUS, title: 'Integrated Local Government Unit', description: 'Seamless integration solutions for local government units.' },
  { id: 7, logo: AERP, title: 'Adamas Enterprise Resource Planning', description: 'ERP solutions to streamline your business operations.' },
  { id: 8, logo: AERP, title: 'SMS Management Services', description: 'SMS Management Services offer a comprehensive solution for businesses and organizations to streamline their communication strategies using SMS.' },
];

const Product = () => {
  return (
    <div className="product-carousel">
      <h2 className="carousel-title">Our Products</h2>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay
        interval={3000}
        stopOnHover
        emulateTouch
      >
        {products.map((product) => (
          <div key={product.id} className="carousel-item">
            <img src={product.image} alt={product.name} className="product-image" />
            <div className="product-info">
              <h3 className="product-name">{product.name}</h3>
            </div>
          </div>
        ))}
      </Carousel>
      <br></br>  <br></br>  <br></br>  <br></br>  <br></br>
      <h2 className="offerings-heading">Our Offerings</h2>
      <div className="offerings-section">
        {offerings.map((offering) => (
          <div key={offering.id} className="offering-item">
            <img src={offering.logo} alt={offering.title} className="offering-logo" />
            <h3 className="offering-title">{offering.title}</h3>
            <p className="offering-description">{offering.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
