import React from 'react';
import './styles/Page.css'; // Ensure the CSS file is in the correct path

const AboutUs = () => {
  return (
    <div className="page-container">
      <h1 className="page-title">About Us</h1>
      <p className="page-description">
        At Adamas, innovation is at the core of everything we do. Our seasoned experts harness
        extensive industry knowledge to guide you through the complexities of modern technology,
        offering tailored strategies to optimize your operations and boost productivity. Whether
        you need IT infrastructure enhancement, cybersecurity fortification, or seamless
        integration of the latest software solutions, we've got you covered.
      </p>

      <div className="two-column-section">
        <div className="section">
          <h2 className="section-title">Who We Are</h2>
          <p className="section-description">
            As your dedicated IT partner, we understand the unique challenges your
            business faces and work collaboratively to provide customized solutions that
            align with your objectives. With a focus on staying ahead in the competitive
            landscape, Adamas Innovation Corporation ensures that your business is equipped 
            with the most advanced and reliable hardware and software solutions available.
          </p>
        </div>

        <div className="section">
          <h2 className="section-title">What We Do</h2>
          <p className="section-description">
            At Adamas Innovation Corporation, we are your comprehensive solution for
            navigating the dynamic realm of information technology. As a leading IT
            consultant and provider of cutting-edge hardware and software solutions,
            we offer a diverse range of services tailored to optimize your business
            operations and fuel your digital transformation.
          </p>
        </div>
      </div>

      <div className="two-column-section">
        <div className="section">
          <h2 className="section-title">Our Mission</h2>
          <p className="section-description">
            Empowering businesses through expert IT consulting and cutting-edge
            hardware/software solutions, Adamas Innovation Corporation is committed to
            optimizing operations and fostering innovation in the digital realm.
          </p>
        </div>

        <div className="section">
          <h2 className="section-title">Our Vision</h2>
          <p className="section-description">
            To be the forefront partner in digital transformation, setting the standard
            for reliability and innovation in IT consultancy, and enabling businesses
            to thrive with seamless integration of hardware and software solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
