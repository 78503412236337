import React from 'react';
import './styles/ContactUs.css'; // Import your custom CSS

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <div className="map-section">
        {/* You can replace the iframe with your actual office map URL */}
        <br></br><br></br><br></br>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.6482899507964!2d121.081505683888!3d14.56209428368779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c700571d1f2b%3A0xea83f9f8517047a5!2sAdamas%20Innovation%20Digital%20Solutions!5e0!3m2!1sen!2sph!4v1724915191218!5m2!1sen!2sph"
          title="Office Location"
          className="map"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
      <div className="form-section">
        <h2 className="form-title">Get in Touch</h2>
        <form className="contact-form">
          <label htmlFor="email">
            Email:
            <input type="email" id="email" name="email" required />
          </label>
          <label htmlFor="mobile">
            Mobile No.:
            <input type="tel" id="mobile" name="mobile" required />
          </label>
          <label htmlFor="message">
            Message:
            <textarea id="message" name="message" rows="4" required></textarea>
          </label>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      
      </div>
    </div>
    
  );
};

export default ContactUs;
